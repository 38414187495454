import tulsi from '../../Data/bod/tulsi.png';

const members = [
    {
      name: 'Mr. Tulsi Kumar Singh',
      title: 'CEO',
      image: tulsi
    },
    {
      name: 'Mr. Tulsi Kumar Singh',
      title: 'CEO',
      image: tulsi
    },
    {
      name: 'Mr. Tulsi Kumar Singh',
      title: 'CEO',
      image: tulsi
    },
    {
      name: 'Mr. Tulsi Kumar Singh',
      title: 'CEO',
      image: tulsi
    },
    {
      name: 'Mr. Tulsi Kumar Singh',
      title: 'CEO',
      image: tulsi
    },
  ];

  export default members;