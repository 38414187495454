import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getAuth, GoogleAuthProvider} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCpOznr6zKNUEuHMaEHFg6OAL_DBeKblT8",
  authDomain: "oipl-ec005.firebaseapp.com",
  projectId: "oipl-ec005",
  storageBucket: "oipl-ec005.appspot.com",
  messagingSenderId: "37288956260",
  appId: "1:37288956260:web:cd264acab98af5163c2a71"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();