const growth = (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
    <path d="M43.5 39.5564C43.5 39.9542 43.342 40.3358 43.0607 40.6171C42.7794 40.8984 42.3978 41.0564 42 41.0564H6C5.60218 41.0564 5.22064 40.8984 4.93934 40.6171C4.65804 40.3358 4.5 39.9542 4.5 39.5564V9.5564C4.5 9.15857 4.65804 8.77704 4.93934 8.49574C5.22064 8.21443 5.60218 8.0564 6 8.0564C6.39782 8.0564 6.77936 8.21443 7.06066 8.49574C7.34196 8.77704 7.5 9.15857 7.5 9.5564V29.9358L16.9387 20.4951C17.0781 20.3557 17.2435 20.245 17.4256 20.1696C17.6077 20.0941 17.8029 20.0552 18 20.0552C18.1971 20.0552 18.3923 20.0941 18.5744 20.1696C18.7565 20.245 18.9219 20.3557 19.0613 20.4951L24 25.4358L33.8794 15.5564H30C29.6022 15.5564 29.2206 15.3984 28.9393 15.1171C28.658 14.8358 28.5 14.4542 28.5 14.0564C28.5 13.6586 28.658 13.277 28.9393 12.9957C29.2206 12.7144 29.6022 12.5564 30 12.5564H37.5C37.8978 12.5564 38.2794 12.7144 38.5607 12.9957C38.842 13.277 39 13.6586 39 14.0564V21.5564C39 21.9542 38.842 22.3358 38.5607 22.6171C38.2794 22.8984 37.8978 23.0564 37.5 23.0564C37.1022 23.0564 36.7206 22.8984 36.4393 22.6171C36.158 22.3358 36 21.9542 36 21.5564V17.677L25.0613 28.6176C24.9219 28.7571 24.7565 28.8678 24.5744 28.9432C24.3923 29.0187 24.1971 29.0576 24 29.0576C23.8029 29.0576 23.6077 29.0187 23.4256 28.9432C23.2435 28.8678 23.0781 28.7571 22.9387 28.6176L18 23.677L7.5 34.177V38.0564H42C42.3978 38.0564 42.7794 38.2144 43.0607 38.4957C43.342 38.777 43.5 39.1586 43.5 39.5564Z" fill="currentColor"/>
  </svg>
);
const safety = (
        <svg className = "icon" xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
        <path d="M24 21.5564C22.7392 21.5568 21.5207 22.0109 20.5672 22.8357C19.6137 23.6605 18.9888 24.8009 18.8068 26.0484C18.6248 27.296 18.8977 28.5673 19.5759 29.6302C20.254 30.693 21.2919 31.4764 22.5 31.837V35.0564C22.5 35.4542 22.658 35.8358 22.9393 36.1171C23.2206 36.3984 23.6022 36.5564 24 36.5564C24.3978 36.5564 24.7794 36.3984 25.0607 36.1171C25.342 35.8358 25.5 35.4542 25.5 35.0564V31.837C26.7081 31.4764 27.746 30.693 28.4241 29.6302C29.1023 28.5673 29.3752 27.296 29.1932 26.0484C29.0112 24.8009 28.3863 23.6605 27.4328 22.8357C26.4792 22.0109 25.2608 21.5568 24 21.5564ZM24 29.0564C23.555 29.0564 23.12 28.9244 22.75 28.6772C22.38 28.43 22.0916 28.0786 21.9213 27.6674C21.751 27.2563 21.7064 26.8039 21.7932 26.3674C21.88 25.931 22.0943 25.5301 22.409 25.2154C22.7237 24.9007 23.1246 24.6864 23.561 24.5996C23.9975 24.5128 24.4499 24.5574 24.861 24.7277C25.2722 24.898 25.6236 25.1864 25.8708 25.5564C26.118 25.9264 26.25 26.3614 26.25 26.8064C26.25 27.4031 26.0129 27.9754 25.591 28.3974C25.169 28.8193 24.5967 29.0564 24 29.0564ZM39 15.5564H33V11.0564C33 8.66945 32.0518 6.38026 30.364 4.69244C28.6761 3.00461 26.3869 2.0564 24 2.0564C21.6131 2.0564 19.3239 3.00461 17.636 4.69244C15.9482 6.38026 15 8.66945 15 11.0564V15.5564H9C8.20435 15.5564 7.44129 15.8725 6.87868 16.4351C6.31607 16.9977 6 17.7607 6 18.5564V39.5564C6 40.352 6.31607 41.1151 6.87868 41.6777C7.44129 42.2403 8.20435 42.5564 9 42.5564H39C39.7957 42.5564 40.5587 42.2403 41.1213 41.6777C41.6839 41.1151 42 40.352 42 39.5564V18.5564C42 17.7607 41.6839 16.9977 41.1213 16.4351C40.5587 15.8725 39.7957 15.5564 39 15.5564ZM18 11.0564C18 9.4651 18.6321 7.93897 19.7574 6.81376C20.8826 5.68854 22.4087 5.0564 24 5.0564C25.5913 5.0564 27.1174 5.68854 28.2426 6.81376C29.3679 7.93897 30 9.4651 30 11.0564V15.5564H18V11.0564ZM39 39.5564H9V18.5564H39V39.5564Z" fill="currentColor"/>
        </svg>
);
const diverse = (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
        <path d="M18.7498 22.387C18.9779 22.2553 19.1673 22.0659 19.2989 21.8378C19.4306 21.6097 19.4998 21.351 19.4998 21.0876V7.58765C19.4984 7.3485 19.4399 7.11314 19.3292 6.90119C19.2184 6.68925 19.0585 6.50686 18.8629 6.36925C18.6673 6.23164 18.4417 6.14279 18.2048 6.11011C17.9679 6.07743 17.7266 6.10187 17.501 6.1814C13.1229 7.73092 9.44101 10.7914 7.11732 14.8125C4.79363 18.8337 3.98052 23.5519 4.82416 28.1189C4.86789 28.3551 4.96769 28.5773 5.11517 28.7668C5.26265 28.9564 5.4535 29.1078 5.67166 29.2083C5.86827 29.3002 6.08275 29.3476 6.29979 29.347C6.56306 29.3471 6.82172 29.2779 7.04979 29.1464L18.7498 22.387ZM16.4998 9.86015V20.2214L7.52229 25.402C7.49979 25.1189 7.49979 24.8339 7.49979 24.5564C7.50245 21.5158 8.34408 18.5349 9.93202 15.942C11.52 13.349 13.7926 11.2445 16.4998 9.86015ZM23.9998 5.0564C23.602 5.0564 23.2204 5.21443 22.9391 5.49574C22.6578 5.77704 22.4998 6.15857 22.4998 6.5564V23.7726L7.72291 32.3808C7.55144 32.4802 7.40136 32.6126 7.28136 32.7703C7.16136 32.9281 7.07381 33.108 7.02377 33.2998C6.97373 33.4916 6.9622 33.6914 6.98984 33.8877C7.01747 34.0839 7.08373 34.2728 7.18479 34.4433C8.91673 37.3876 11.3917 39.8253 14.362 41.5123C17.3323 43.1994 20.6936 44.0765 24.1095 44.056C27.5254 44.0355 30.876 43.1181 33.8258 41.3955C36.7756 39.673 39.2212 37.2058 40.9177 34.2409C42.6142 31.276 43.502 27.9175 43.4924 24.5015C43.4828 21.0856 42.5761 17.7321 40.8629 14.7768C39.1498 11.8215 36.6903 9.36812 33.7309 7.66218C30.7714 5.95625 27.4157 5.05767 23.9998 5.0564ZM23.9998 41.0564C21.3812 41.0494 18.8016 40.4226 16.4718 39.2272C14.142 38.0318 12.1283 36.3019 10.5954 34.1789L24.7554 25.9289C24.9832 25.7974 25.1724 25.6083 25.3041 25.3805C25.4357 25.1528 25.5051 24.8944 25.5054 24.6314V8.1239C29.7362 8.50775 33.6559 10.5086 36.4485 13.7099C39.2411 16.9112 40.6913 21.0662 40.4973 25.31C40.3033 29.5537 38.48 33.5591 35.4069 36.4922C32.3339 39.4253 28.2479 41.0602 23.9998 41.0564Z" fill="currentColor"/>
        </svg>
);
const principleData = [
        {
                icon: growth,
                title: 'SUPERIOR RETURNS'
        },
        {
                icon: safety,
                title: 'SAFETY'
        },
        {
                icon: diverse,
                title: 'DIVERSIFICATION'
        }
]

export default principleData;